//Alert
document.addEventListener("DOMContentLoaded", function() {
    const modalElement = document.getElementById("api-message-modal");

    if (modalElement) {
        const myModal = new bootstrap.Modal(modalElement);
        myModal.show();
    }
});
// END - Alert

//Clear and Hide Input after uploading an CSV File
var csvFile = document.getElementById('csvFile');
if (csvFile) {
    csvFile.addEventListener('change', function() {
        var input = document.getElementById('input');
        if (input) {
            document.getElementById('input').value = '';
        }

        var elementIds = [
            'input', 'custom1', 'custom2', 'custom3', 'custom4', 'custom5',
            'toolStepHeadlineMaininput', 'toolStepHeadlineCustom1', 'toolStepHeadlineCustom2',
            'toolStepHeadlineCustom3', 'toolStepHeadlineCustom4', 'toolStepHeadlineCustom5'
        ];

        for (var i = 0; i < elementIds.length; i++) {
            var element = document.getElementById(elementIds[i]);
            if (element) {
                element.style.display = 'none';
            }
        }
    });
}
// END - Clear and Hide Input after uploading an CSV File

// Adjust Input Height
function adjustTextareaHeight() {
  // Get all elements with the class 'adjust-height'
  var textareas = document.getElementsByClassName('adjust-height');
  
  // Loop through each textarea element
  for (var i = 0; i < textareas.length; i++) {
    var textarea = textareas[i];
    
    // Set the height of the textarea to 'auto' and then to its scrollHeight
    textarea.style.height = 'auto';
    textarea.style.height = (textarea.scrollHeight) + 'px';
  }
}

// Wait for the DOM to be fully loaded
window.addEventListener('DOMContentLoaded', function() {
  // Get all elements with the class 'adjust-height'
  var textareas = document.getElementsByClassName('adjust-height');
  
  // Loop through each textarea element
  for (var i = 0; i < textareas.length; i++) {
    var textarea = textareas[i];
    
    // Call the adjustTextareaHeight function initially
    adjustTextareaHeight();
    
    // Add an input event listener to call adjustTextareaHeight when the textarea content changes
    textarea.addEventListener('input', adjustTextareaHeight);
    textarea.addEventListener('change', adjustTextareaHeight);
    textarea.addEventListener('paste', adjustTextareaHeight);
  }
});
// END - Adjust Input Height

$(document).ready(function() {
    function resizeIframe() {
        var iframe = $('.full-height');
        iframe.on('load', function() {
            var iframeContentHeight = iframe[0].contentWindow.document.body.scrollHeight;
            iframe.height(iframeContentHeight);
        });
    }

    // Initial call to resizeIframe
    resizeIframe();

    $(function() {
        $('[data-bs-toggle="tooltip"]').tooltip({
            trigger: 'manual' // Prevent tooltip from automatically closing
        }).on('mouseenter click', function() {
            $(this).tooltip('show'); // Show the tooltip on hover/click
            // Attach a mouseleave event to the tooltip to detect when the mouse leaves
            $(this).next('.tooltip').on('mouseleave', function() {
                $('[data-bs-toggle="tooltip"]').tooltip('hide'); // Hide the tooltip
            });
            // Attach a click event to the document to detect when a click occurs outside of the tooltip
            $(document).on('click.tooltip', function() {
                $('[data-bs-toggle="tooltip"]').tooltip('hide'); // Hide the tooltip
                $(document).off('click.tooltip'); // Remove the click event from the document
            });
        });
    });

    /* TinyMCE */
    tinymce.init({
        selector: '.tinymce',
        height: 500,
        plugins: 'preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons',
        menubar: '',
        toolbar: 'undo redo | fullscreen template | bold italic underline strikethrough | code | blocks | alignleft aligncenter alignright | outdent indent |  numlist bullist |  emoticons | image media link',
        toolbar_sticky: true,
        image_caption: true,
        quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
        noneditable_class: 'mceNonEditable',
        toolbar_mode: 'sliding',
        contextmenu: 'link table',
        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }',
        language: 'de',
        link_context_toolbar: true,
        paste_as_text: true,
        visualblocks_default_state: true,
        end_container_on_empty_block: true,
        browser_spellcheck: true,
    });

    /* TinyMCE */
    tinymce.init({
        selector: '.tinymce-inspector',
        height: 600,
        plugins: 'bootstrap preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons',
        editimage_cors_hosts: ['picsum.photos'],
        menubar: '',
        toolbar: 'bootstrap | undo redo | fullscreen template | bold italic underline strikethrough | code | blocks | alignleft aligncenter alignright | outdent indent |  numlist bullist |  emoticons | image media link',
        toolbar_sticky: true,
        quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
        //noneditable_class: 'mceNonEditable',
        toolbar_mode: 'sliding',
        contextmenu: 'link image table bootstrap',
        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }',
        language: 'de',
        link_context_toolbar: true,
        paste_as_text: true,
        visualblocks_default_state: true,
        end_container_on_empty_block: true,
        browser_spellcheck: true,
        relative_urls: false,
        images_upload_url: '/api/inspector/projects/upload-img/',
        images_reuse_filename: true,
        image_caption: true,
        bootstrapConfig: {
            url: 'https://hosono.ai/lib/tinymce-bootstrap-plugin/plugin/',
            iconFont: 'font-awesome-solid',
            imagesPath: '/api/inspector/projects/upload-img/',
            key: 'LwKsdqZQYUZqRUxVd9ReQCOUukn/LE9ivw/+VBTBAzGvyxu1XFtd31dxyf2mlzlo0/4wNdWfrLRak39bWlFwbBHze1+9GzGf5wQ/a7QI/ic='
        },
    });

    tinymce.init({
        selector: '.tinymce-sm',
        height: 200,
        plugins: 'preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons',
        menubar: '',
        toolbar: 'undo redo | fullscreen template | bold italic underline strikethrough | code | blocks | alignleft aligncenter alignright | outdent indent |  numlist bullist |  emoticons | image media link',
        toolbar_sticky: true,
        image_caption: true,
        quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
        noneditable_class: 'mceNonEditable',
        toolbar_mode: 'sliding',
        contextmenu: 'link table',
        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }',
        language: 'de',
        link_context_toolbar: true,
        paste_as_text: true,
        visualblocks_default_state: true,
        end_container_on_empty_block: true,
        browser_spellcheck: true,
    });

    //SELECT 2
    $('select:not(.no-select2)').select2({
        sorter: data => data.sort((a, b) => a.text.localeCompare(b.text)),
    });

    $('.no-select2 select').select2('destroy');

    $(document).on('select2:open', () => {
        document.querySelector('.select2-search__field').focus();
    });


    /*@@ TODO 
        Test the integration of the following:
        - instead of having button type submit on click event, we need to check the form submit 
        - when the form is submitting, we check if there is the button - if not then we don't show the modal
    */
    // //Loader & Timer
    // $("button[type='submit']:not(.no-loader)").click(function() {
       
    // });
    $("form#ai-tool").submit(function(event) {

        if (!this.checkValidity()) {
            event.preventDefault(); // Prevent form submission if invalid
            event.stopPropagation(); // Stop propagation to avoid side effects
            return; // Exit the function
        }

        if (event.target.querySelector("button[type='submit']:not(.no-loader)") == null) {
            return
        }
         // Show Popup
        $('#loader').modal('show'); // This opens the modal
        
        var minTimeout = 8000; // Minimum timeout in milliseconds (8 seconds)
        var maxTimeout = 11000; // Maximum timeout in milliseconds (11 seconds)

        // Generate a random timeout value between minTimeout and maxTimeout
        var randomTimeout = Math.floor(Math.random() * (maxTimeout - minTimeout + 1)) + minTimeout;

        setTimeout(function() { 
            $('#loader .modal-header').removeClass('bg-dark').addClass('bg-info');
            $('#loader-modal-label').text("Deine Anfrage wird nun bearbeitet, bitte schließe diesen Tab nicht!");  
        }, randomTimeout);

        // After 3 Minutes Show Warning
        setTimeout(function() { 
            $('#loader .modal-header').removeClass('bg-info').addClass('bg-danger');
            $('#loader .stage').html('<i class="fa-solid fa-rotate-right" role="button" onclick="location.reload()"></i>');
            $('#loader-modal-label').text("Die Anfrage ist möglicherweise hängen geblieben, bitte versuche es erneut!");     
        }, 180000);
        //END LOADER

        //TIMER
        var totalSeconds = 0;
        var countdown = $('#countdown');

        // Sekundenzähler aktualisieren und alle Sekunde ausführen
        setInterval(function() {
            ++totalSeconds;
            var minutes = Math.floor(totalSeconds / 60);
            var seconds = totalSeconds - minutes * 60;
            countdown.html(formatTime(minutes) + ':' + formatTime(seconds));
        }, 1000);

        // Hilfsfunktion, um die Zeit in einem zweistelligen Format anzuzeigen
        function formatTime(time) {
            if (time < 10) {
                return '0' + time;
            } else {
                return time;
            }
        }
        //END TIMER
    });



    //Tabs
    var myTab = document.getElementById('jsTab')
    if (myTab !== null) {
        var tabList = myTab.querySelectorAll('[data-bs-toggle="tab"]')
        for (var i = 0; i < tabList.length; i++) {
            tabList[i].addEventListener('click', function(event) {
                event.preventDefault()
                bootstrap.Tab.getInstance(myTab).show(event.target)
            })
        }
    }

    // Start ClipboardJS
    var clipboard = new ClipboardJS('.clipboard');
    // END - Start ClipboardJS

    // Start AiTool Recalculate Word Number
    $('.words').html($("#words").val());
    $('.quantity').html($("#quantity").val());
    // END AiTool Recalculate Word Number
});